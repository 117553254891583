
import breakpoints from "@/assets/style/_breakpoints.scss"

export default {
  data: () => ({
    partners: [],
  }),

  async fetch () {
    await this.fetchPartners()
  },

  computed: {
    swiperOpts () {
      return {
        slidesPerView: this.partners.length > 1 ? 1.2 : 1,
        spaceBetween: 16,
        breakpoints: {
          [breakpoints.xs]: {
            slidesPerView: 2,
          },
          [breakpoints.sm]: {
            slidesPerView: 3,
          },
          [breakpoints.md]: {
            spaceBetween: 32,
          },
          [breakpoints.lg]: {
            slidesPerView: 4,
          },
        },
      }
    },
  },

  methods: {
    async fetchPartners () {
      const [res, err] = await this.$api.partner.getPartners()
      if (err) console.error(err.response.data)
      else this.partners = this.$api.helpers.getList(res)
    },
  },
}
