// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JJKWX78y{padding:0 0 2.5rem}@media(min-width:667.98px){.JJKWX78y{padding:0 0 6.25rem}}.CJd5gtwC{margin-bottom:1.25rem}@media(min-width:667.98px){.CJd5gtwC{margin-bottom:3.75rem}}.mRngnUjt{flex-direction:column}.mRngnUjt,.Kejd6owC{align-items:center;display:flex}.Kejd6owC{background-color:var(--color-text);border-radius:var(--border-radius);height:11.5rem;justify-content:center;margin-bottom:var(--spacing-lg);width:100%}@media(max-width:667.98px){.Kejd6owC{height:9.38rem}}.Kejd6owC img{height:37%;-o-object-fit:contain;object-fit:contain;width:50%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"wrapper": "JJKWX78y",
	"partners": "CJd5gtwC",
	"partner": "mRngnUjt",
	"partnerImg": "Kejd6owC"
};
module.exports = ___CSS_LOADER_EXPORT___;
